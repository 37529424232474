import { useState } from "react";

export default function FAC() {
    const [openIndex, setOpenIndex] = useState(null);

    const FAQItem = ({ question, answer, isOpen, onToggle }) => (
        <div className="faq-item mt-[24px] hover:cursor-pointer" onClick={onToggle}>
            <div className="flex justify-between">
                <h3 className="text-[#FFF] text-[18px] cursor-pointer font-normal leading-7" >
                    {question}
                </h3>
                {
                    isOpen ?
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        : <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                }
            </div>
            {isOpen && <p className="text-white opacity-80 font-normal text-[16px]">{answer}</p>}
        </div>
    );

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const faqs = [
        {
            question: 'Como posso criar uma conta?',
            answer: 'Você pode criar uma conta clicando no botão "Registrar" na página inicial.',
        },
        {
            question: 'Quais são os métodos de pagamento aceitos?',
            answer: 'Aceitamos cartões de crédito, PayPal e transferência bancária.',
        },
        {
            question: 'Como faço para redefinir minha senha?',
            answer: 'Você pode redefinir sua senha na página de login clicando em "Esqueci minha senha".',
        },

    ];
    return (
        <div className="p-[15px] rounded-lg bg-[#ff0000b3] mt-[78px] mb-[150px]" >
            {faqs.map((faq, index) => (
                <FAQItem
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                    isOpen={openIndex === index}
                    onToggle={() => handleToggle(index)}
                />
            ))}
        </div>
    )
}