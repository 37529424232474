export default function Footer() {
    return (
        <div className="bottom-0 left-0 w-full h-[103px] bg-[#ff0000b3] flex items-center justify-center">
            <h1 className="text-[16px] text-white">
                Desenvolvido por{' '}
                <a className="underline" target="_blank" rel="noopener noreferrer" href="https://trigueirotech.com.br/">
                    Trigueiro Tech
                </a>
            </h1>
        </div>
    );
}
