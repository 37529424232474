
import React from 'react';
import { useState } from 'react';
import styles from './style.module.css';

export default function Login() {
    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    

    const handleEmail = (event) => {
        setEmail(event.target.value)
    }

    const handlePassword = (event) => {
        setPassword(event.target.value)
    }
    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const handleLogin = (event) => {
        event.preventDefault()
        const regex = /^(?=.*\d)(?=(.*\W){2})(?=.*[a-zA-Z])(?!.*\s).{15,80}$/;
        if (!email || !password   ) {
            alert("Por favor, preencha todos os campos.");
            return;
        }
        if (!regex.test(password)) {
            alert("Senha invalida.");
            return;
        }
        if (!validateEmail(email)) {
            alert("Email invalido");
            return;
        }
        window.sendRequest("/api/login",{
            email,
            password,
        }).then(()=> {
            window.location.href="/dashboard"
        }).catch(({errorCode})=>{
            window.TriggerError(errorCode)
        })

        
    }

    return (
        <div className={`relative w-screen h-screen flex justify-center items-center`}>
            <div className={` w-[444px] box-border p-8 rounded-3xl ${styles.container}`}>
                <header className='flex flex-col items-center'>
                    <img className='w-[138px] h-[92px]' src="logo.svg" alt="" />
                    <h1 className='mt-[24px] font-semibold text-[30px]'>Faça login na sua conta</h1>
                    <p className='mt-[12px] text-[16px] text-secondaryColor'>Bem vindo de volta! Por favor, insira seus dados.</p>
                </header>
                <form className='mt-[32px] flex flex-col gap-7' onSubmit={handleLogin}>

                    <div className='flex flex-col'>
                        <label className='text-primaryColor' htmlFor="email">Email</label>
                        <input
                            spellCheck="false"
                            className={`h-11 ${styles.i_value} rounded-lg px-4`}
                            type="email"
                            name='email'
                            onChange={handleEmail}
                        />
                    </div>

                    <div className='flex flex-col'>
                        <label className='text-primaryColor' htmlFor="password">Senha</label>
                        <input 
                            spellCheck="false" 
                            className={`h-11 ${styles.i_value} rounded-lg px-4`} 
                            type="password" 
                            name='password'
                            onChange={handlePassword}
                        />
                    </div>

                    <button className='h-[44px] bg-[#D33D2C] rounded-lg text-white hover:opacity-90' type='submit'>Entrar</button>
                </form>

            </div>
        </div>
    )
}