import Container from "../../Components/LP/Container";
import Header from "../../Components/Header";
import Article from "../../Components/LP/Article";
import Card from "../../Components/Products/Card";
import Player from "../../Components/LP/Player";
import Title from "../../Components/LP/Title";
import Search from "../../Components/Products/Search";
import Footer from "../../Components/Footer";
import Preview from "../../Components/Preview";
import { useContext, useState } from "react";
import ImageGallery from "react-image-gallery";
import { TnetContext } from "../../Contexts/Context";
import Fixed from "../../Components/LP/Fixed";

export default function Products() {
    const {
        products
    } = useContext(TnetContext)

    const Nav = [
        {
            label: 'Home',
            route: '/'
        },
        {
            label: 'Scripts',
            route: '/products'
        },

       /*  {
            label: 'Status',
            route: '/status'
        } */
    ]

    const [dialog, setDialog] = useState(false)

    const [dialogCentral, setDialogCentral] = useState(false)

    const [images, setImages] = useState([])
    const [description, setDescription] = useState('')
    const [name, setName] = useState('')
    const [preview,setPreview] = useState(null)
    const [category, setCategory] = useState('')

    const dialogData = ({images,description, name, preview,category})=>{
        setDialog(true)
        setDescription(description)
        setName(name)
        setPreview(preview)
        setCategory(category)
        const formattedImages = images.map((url) => ({
            original: url,
            thumbnail: url, // ou substitua por uma versão reduzida, se disponível
        }));

        setImages(formattedImages)
    }

    return (
        <div>
            
            <Header navList={Nav} />
            <Container>
                <Title />
                <Search />
                <div className="flex w-full justify-center flex-wrap gap-7 mt-[27px]">
                    {
                        products.map((e,key)=>{
                            return <Card key={key} handler={dialogData} data={e}/>
                        })
                    }
                   

                </div>
                <div className="w-full flex justify-center">
                    <button className="btn mb-[80px] bg-[#ff0000b2] p-[20px] rounded-[8px] flex items-center gap-2 text-white text-[16px] font-semibold mt-6">
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0001 4.16666V15.8333M10.0001 15.8333L15.8334 9.99999M10.0001 15.8333L4.16675 9.99999" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </span>Ver mais</button>
                </div>
            </Container>
            <Footer />


            <Preview title='Formulario de interesse' isOpen={dialog} setIsOpen={setDialog} backendMachine={true}>

                <ImageGallery
                    items={images}
                    showThumbnails={false}
                    autoPlay={true}
                    bulletClass={true}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    showBullets={true}
                    disableKeyDown={true}
                  
                    additionalClass="!rounded-[8px] mt-[30px] w-[925px] object-cover h-[457px] overflow-hidden"
                />

                <div className="flex flex-col justify-left w-full">
                    <p className="text-[#C64141] text-[14px] font-semibold mt-[32px]">{category}</p>
                    <div className="flex justify-between items-center">
                        <h1 className="text-[24px] font-semibold">{name}</h1>
                       
                    </div>

                    {
                        preview && <a target="_blank" className="text-[13px] underline text-[#C64141]" href="https://music.youtube.com/watch?v=mr09lDl_Tfk&list=RDCLAK5uy_nz7xEkComeEORRASLNdSCz8lkNP5-DCjE">
                            Ir para demonstração do sistema
                        </a>
                    }

                    <p className="title text-[16px] font-normal mt-[16px]">
                        {description}
                    </p>
                </div>
              

                <button className="btn w-full h-[45px] mt-[17px] rounded-lg text-white text-[18px] bg-[#ff0000bf]">Reservar Minha Base</button>
                <button onClick={()=>setDialog(false)} className="btn customBorder w-full h-[45px] mt-[17px] rounded-lg text-[#3E3E3E] text-[18px] bg-white">Desistir do projeto</button>
            </Preview>

          

        </div>
    )
}