import './style.css'
import { UseTnetCtx } from "../../Contexts/Context"
import { useState } from 'react'

export default function MachineList() {

    const {
        users,
        setUsers,
        setUserForms,
        setisModalNewUser,
        setActiveClient,
    } = UseTnetCtx()
   
    const editClient = (Discord) => {
        let User = users.find((e)=> e.Discord == Discord)
        if(User) {

            setisModalNewUser(true)

            setTimeout(() => {
                setActiveClient(User.id)
                setUserForms({
                    Nome: User.Nome,
                    Email: User.Email,
                    Telefone: User.Telefone,
                    Discord: User.Discord,
                })
            }, 0);
            
        } else {
            alert('Cliente não mapeado')
        }
    }

    const deleteClient = (Discord) => {
        alert('deletar cliente')
    }

    const [wordFilter, setFilter] = useState('')
    
    const handleFilter = (event) => {
        setFilter(event.target.value)
    }

    const filteredData = users.filter((e) =>
      e.Email.toLowerCase().includes(wordFilter.toLowerCase())
    ) 

    return (
        <div className="machineList max-h-[800px] overflow-auto bg-red-500 relative overflow-x-auto sm:rounded-lg w-[1216px] mt-[34px] mb-[300px]">
            <div className="shadow-md flex items-center justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-0 pb-4 bg-white">

                <label for="table-search" className="sr-only">Pesquisar</label>
                <div className="relative">
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input onChange={handleFilter} type="text" id="table-search-users" className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Pesquisar" />
                </div>

            </div>
            <table className="w-full text-sm text-left rtl:text-right">
                <thead className="text-xs rounded-3xl text-[#475467] bg-[#F9FAFB] font-medium">
                    <tr className="rounded-t-xl text-[12px]">

                        <th scope="col" className="px-6 py-3">
                            Nome do Cliente
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Status
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Players Conectados
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Status da máquina
                        </th>
                        <th scope="col" className="px-6 py-3">

                        </th>
                        <th scope="col" className="px-6 py-3">

                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredData.map((e, key) => {
                            return (
                                <tr key={key} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50">

                                    <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                                        <img className="w-10 h-10 rounded-full" src="logo.png" alt="Jese image" />
                                        <div className="ps-3 text-[14px]">
                                            <div className="text-base font-semibold">{e.Nome}</div>
                                            <div className="font-normal text-gray-500">{e.Email}</div>
                                        </div>
                                    </th>
                                    <td className="px-6 py-4">
                                        <h1 className="text-[#027A48] px-2 bg-[#ECFDF3] inline-block rounded-2xl">Operando Normalmente</h1>
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className="flex items-center">
                                            <div className="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div> 300
                                        </div>
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className="h-full flex items-center gap-3">
                                            <div className="relative w-[257px] h-[8px] rounded bg-[#EAECF0] overflow-hidden">
                                                <div className="absolute w-[50%] h-full rounded bg-[#F00]"></div>
                                            </div>
                                            <h1 className="text-[#344054] text-[14px]">70%</h1>

                                        </div>
                                    </td>
                                    <td className="px-6 py-4">
                                        <svg onClick={()=> editClient(e.Discord)} className="p-1 hover:cursor-pointer" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.8967 25.0964C12.935 24.7518 12.9541 24.5795 13.0062 24.4185C13.0525 24.2756 13.1178 24.1396 13.2005 24.0142C13.2937 23.8729 13.4163 23.7504 13.6614 23.5052L24.6667 12.5C25.5871 11.5795 27.0795 11.5795 28 12.5C28.9205 13.4205 28.9205 14.9128 28 15.8333L16.9948 26.8386C16.7496 27.0837 16.627 27.2063 16.4857 27.2995C16.3604 27.3821 16.2244 27.4475 16.0815 27.4937C15.9205 27.5459 15.7482 27.565 15.4036 27.6033L12.5833 27.9167L12.8967 25.0964Z" stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                    </td>
                                    <td className="px-6 py-4">
                                        <svg onClick={()=> deleteClient(e.Discord)} className="p-1 hover:cursor-pointer" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.8967 25.0964C12.935 24.7518 12.9541 24.5795 13.0062 24.4185C13.0525 24.2756 13.1178 24.1396 13.2005 24.0142C13.2937 23.8729 13.4163 23.7504 13.6614 23.5052L24.6667 12.5C25.5871 11.5795 27.0795 11.5795 28 12.5C28.9205 13.4205 28.9205 14.9128 28 15.8333L16.9948 26.8386C16.7496 27.0837 16.627 27.2063 16.4857 27.2995C16.3604 27.3821 16.2244 27.4475 16.0815 27.4937C15.9205 27.5459 15.7482 27.565 15.4036 27.6033L12.5833 27.9167L12.8967 25.0964Z" stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}