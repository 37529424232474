import './style.css';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function ChartComponent() {
    const chartRef = useRef(null);

    useEffect(() => {
        const chart = chartRef.current;

        if (chart) {
            const ctx = chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, 'rgba(224, 195, 155, 1)');
            gradient.addColorStop(1, 'rgba(100, 100, 0, 0)');

            chart.data.datasets[0].backgroundColor = gradient;
            chart.update();
        }
    }, []);

    return (
        <Line
            ref={chartRef}
            width={672}
            height={200}
            data={{
                labels: ['Jun', 'Jul', 'Aug'],
                datasets: [
                    {
                        label: 'Numbers',
                        data: [200, 19, 3, 5, 2, 3],
                        borderColor: 'rgb(75, 192, 192)', // Line color
                        fill: true, // Enable fill for background gradient
                    },
                    {
                        label: '',
                        data: [3, 2, 1],
                        borderColor: 'rgb(255, 99, 132)', // Line color
                        fill: false, // No fill for this dataset
                    },
                ],
            }}
            options={{
                tension: 0.1,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                elements: {
                    point: {
                        radius: 0,
                    },
                },
                responsive: true,
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        display: true,
                        min: 0,
                        max: 100,
                        ticks: {
                            display: false,
                        },
                        grid: {
                            display: false,
                        },
                    },
                },
            }}
        />
    );
}
