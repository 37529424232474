export default function Counter(){
    return (
        <div className="bg-[#ff0000b2] p-[64px] sm:p-[16px] rounded-2xl mt-[84px] sm:mx-[5px]">
            <ul className="text-white flex gap-[32px] sm:gap-1 items-center justify-center text-center">
                <li className="text-[50px] sm:text-[35px] flex flex-col ">25 <span className="text-[18px] sm:text-[14px] text-center">Pre vendas</span></li>
                <li className="text-[50px] sm:text-[35px] flex flex-col">60 <span className="text-[18px] sm:text-[14px] text-center">Sistemas inclusos</span></li>
                <li className="text-[50px] sm:text-[35px] flex flex-col">350 <span className="text-[18px] sm:text-[14px] text-center">Reports solucionados</span></li>
                <li className="text-[50px] sm:text-[35px] flex flex-col">75 <span className="text-[18px] sm:text-[14px] text-center">Unidades restantes</span></li>
            </ul>
        </div>
    )
}