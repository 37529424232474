import { useEffect, useState } from "react";
import Container from "../Container";

export default function Queue() {
    const [phone, setPhone] = useState('');
    const [isValid, setIsValid] = useState(true);

    const handleChange = (e) => {
        const { value } = e.target;
        console.log('setei ', phone)
        setPhone(value);
        // ALIG INTEGRAÇÃO phone
    };


    function formatPhoneNumber(value) {
        const cleaned = value.replace(/\D/g, ''); // Remove tudo que não for dígito
        const match = cleaned.match(/^(\d{0,2})(\d{0,5})(\d{0,4})$/);

        if (match) {
            let formatted = '';

            if (match[1]) {
                formatted = `(${match[1]}`;
            }
            if (match[2]) {
                formatted += `) ${match[2]}`;
            }
            if (match[3]) {
                formatted += `-${match[3]}`;
            }

            return formatted;
        }

        return value.slice(0, 15); // Garante que o valor não ultrapasse 15 caracteres formatados
    }

    const contact = (e) => {
        e.preventDefault()
        alert('mandar webhook ' + phone)
    }

    const [playerCount, setPlayerCount] = useState(50); // Número inicial de jogadores

    useEffect(() => {
        const updatePlayerCount = () => {
            const currentHour = new Date().getHours();
            let delta;

            // Lógica para determinar o delta de jogadores
            if (currentHour >= 6 && currentHour < 12) {
                // Manhã
                delta = Math.floor(Math.random() * 10) - 5; // Varia de -5 a +4
            } else if (currentHour >= 12 && currentHour < 18) {
                // Tarde
                delta = Math.floor(Math.random() * 20) - 10; // Varia de -10 a +9
            } else {
                // Noite
                delta = Math.floor(Math.random() * 30) - 15; // Varia de -15 a +14
            }

            setPlayerCount((prevCount) => Math.max(0, prevCount + delta)); // Atualiza o número de jogadores, garantindo que não fique negativo
        };

        // Atualiza a contagem a cada 1 minuto
        const intervalId = setInterval(updatePlayerCount, 60000);

        // Chama a função imediatamente para ter um valor inicial
        updatePlayerCount();

        // Limpa o intervalo ao desmontar o componente
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="w-full h-[202px] bg-[#F8F8F8] flex items-center justify-center">
            <div className="px-[404px] sm:px-[16px] lg:px-[30px] md:px-[30px] w-full relative flex sm:flex-col md:flex-col justify-between items-center">
                <div className="flex flex-col sm:text-center md:text-center">
                    <h1 className="title text-[28px] font-semibold sm:text-[22px]">Garanta já seu acesso</h1>
                    <p className="opacity-70 text-[18px] sm:text-[16px] font-medium api-get-player-node">{playerCount} Pessoas se conectando..</p>
                </div>
                <div className="flex sm:mt-4  md:mt-[10px]">
                    <form action="submit">
                        <input
                            className="w-[383px] sm:w-[170px] h-[45px] border border-[#D0D5DD] rounded-lg color-[#ffffffcc] focus:outline-none focus:border-none "
                            type="tel"
                            placeholder="Digite seu telefone"
                            onInput={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                            value={phone}
                            onChange={handleChange}
                        />
                        <button onClick={contact} className="btn bg-[#dc1b1bbf] ml-[10px] py-[12px] sm:px-[8px] px-[16px] rounded-lg text-white text-[18px] leading-5"

                        >Reservar minha base</button>
                    </form>
                </div>
            </div>
        </div>
    )
}