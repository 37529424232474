import { useState } from "react";

export default function Search() {
    const [phone, setPhone] = useState('');
    const [isValid, setIsValid] = useState(true);

    const handleChange = (e) => {
        const { value } = e.target;
        // Regex para validar formato (00) 00000-0000
        //  const regex = /^\(\d{2}\) \d{5}-\d{4}$/;
        console.log('setei ', phone)
        setPhone(value);
        //setIsValid(regex.test(value));
    };


    function formatPhoneNumber(value) {
        const cleaned = value.replace(/\D/g, ''); // Remove tudo que não for dígito
        const match = cleaned.match(/^(\d{0,2})(\d{0,5})(\d{0,4})$/);

        if (match) {
            let formatted = '';

            if (match[1]) {
                formatted = `(${match[1]}`;
            }
            if (match[2]) {
                formatted += `) ${match[2]}`;
            }
            if (match[3]) {
                formatted += `-${match[3]}`;
            }

            return formatted;
        }

        return value.slice(0, 15); // Garante que o valor não ultrapasse 15 caracteres formatados
    }

    const contact = (e) => {
        e.preventDefault()
        // alert('mandar webhook ' + phone)
        //ALIG INTEGRAR DADOS ACIMA
        window.sendRequest("/api/forms/sendFormPhone", { phone })
            .catch(({ errorCode }) => {
                window.TriggerError(errorCode);
            })
    }

    return (
        <div className="flex w-full justify-center">
            <form action="submit" className="mt-[29px] z-10">
                <form action="submit">
                    <input
                        className="w-[383px] sm:w-[170px] h-[45px] border border-[#D0D5DD] rounded-lg color-[#ffffffcc] focus:outline-none focus:border-none "
                        type="tel"
                        placeholder="Digite seu telefone"
                        onInput={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                        value={phone}
                        onChange={handleChange}
                    />
                    <button onClick={contact} className="btn bg-[#dc1b1bbf] ml-[10px] py-[12px] sm:px-[8px] px-[16px] rounded-lg text-white text-[18px] leading-5"

                    >Reservar minha base</button>
                </form>
            </form>
        </div>
    )
}