import { useState, useEffect } from "react";
import Container from "../../Components/Container";
import Header from "../../Components/Header";
import { UseTnetCtx } from "../../Contexts/Context";
import './style.css';
let lastReq = 0;

export default function Logs() {
    const { logs, formatDate } = UseTnetCtx();

    const [wordFilter, setFilter] = useState('');
    const [page, setPage] = useState(1);
    const [limit] = useState(10); // Número de logs por página

    const handleFilter = (event) => {
        setFilter(event.target.value);
    };

    // Filtrar logs com base na palavra-chave
    const filteredData = logs.filter((e) =>
        e.message?.toLowerCase().includes(wordFilter.toLowerCase())
    );

    const sendBackendFilter = (event)=>{
        event.preventDefault()

        let words = wordFilter
        window.fetchLogs(words,true)
          
        // mandar pro back filtro texto 
    }

    // Total de logs filtrados
    const totalLogs = filteredData.length;
    const totalPages = Math.ceil(totalLogs / limit);

    // Dados paginados
    const paginatedData = filteredData.slice((page - 1) * limit, page * limit);
    useEffect(()=>{
     //   window.fetchLogs(wordFilter == "" ? undefined : wordFilter);
    },[])
    let drained = totalPages != (totalLogs / limit);
    
    // Atualizar a página se o filtro mudar para evitar exibir uma página vazia
    useEffect(() => {
      //  window.fetchLogs(wordFilter == "" ? undefined : wordFilter);
        if (page > totalPages ) {
            if (drained) {
                setPage(1);
            } else {
                window.fetchLogs(wordFilter == "" ? undefined : wordFilter);
            }
        }
    }, [page,totalPages]);

    const Nav = [
        {
            label: 'Home',
            route: '/'
        },
        {
            label: 'Dashboard',
            route: '/dashboard'
        },
        {
            label: 'Logs',
            route: '/logs'
        },
       
    ]

    return (
        <div className="LogsContainer">
            <Header navList={Nav} />
            <Container>
                <div className="mt-[43px]">
                    <header>
                        <h1 className="text-[25px] text-[#2e263dd9] font-semibold">Logs do sistema</h1>
                        <p className="text-[#979BA0]">Tenha uma visão geral de todos os White Label da Hydra Hub</p>
                    </header>
                </div>
                <div className="mt-6 flex gap-6">
                    <div className="flex-1 border-[#D0D5DD] rounded-lg w-full px-3 h-[40px] flex gap-2" type="text" >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.0257 13.8475L18.5948 17.4158L17.4157 18.595L13.8473 15.0258C12.5196 16.0902 10.8682 16.6691 9.1665 16.6667C5.0265 16.6667 1.6665 13.3067 1.6665 9.16667C1.6665 5.02667 5.0265 1.66667 9.1665 1.66667C13.3065 1.66667 16.6665 5.02667 16.6665 9.16667C16.6689 10.8683 16.09 12.5198 15.0257 13.8475ZM13.354 13.2292C14.4116 12.1416 15.0022 10.6837 14.9998 9.16667C14.9998 5.94333 12.389 3.33333 9.1665 3.33333C5.94317 3.33333 3.33317 5.94333 3.33317 9.16667C3.33317 12.3892 5.94317 15 9.1665 15C10.6835 15.0024 12.1414 14.4118 13.229 13.3542L13.354 13.2292ZM10.1482 5.98C9.90244 6.091 9.68732 6.25996 9.52125 6.47238C9.35518 6.6848 9.24313 6.93434 9.19471 7.19959C9.14629 7.46484 9.16294 7.73788 9.24325 7.99528C9.32355 8.25268 9.46511 8.48675 9.65577 8.67741C9.84643 8.86806 10.0805 9.00962 10.3379 9.08993C10.5953 9.17023 10.8683 9.18689 11.1336 9.13847C11.3988 9.09005 11.6484 8.97799 11.8608 8.81192C12.0732 8.64586 12.2422 8.43073 12.3532 8.185C12.5722 8.89635 12.5468 9.66054 12.2811 10.3558C12.0154 11.051 11.5246 11.6374 10.887 12.0213C10.2494 12.4053 9.50157 12.5648 8.76277 12.4745C8.02397 12.3841 7.33667 12.0491 6.81037 11.5228C6.28407 10.9965 5.94906 10.3092 5.85871 9.5704C5.76836 8.8316 5.92788 8.08382 6.31184 7.4462C6.69581 6.80859 7.28212 6.31782 7.97738 6.0521C8.67263 5.78639 9.43682 5.761 10.1482 5.98Z" fill="#2E263D" fillOpacity="0.5" />
                        </svg>
                        <input onChange={handleFilter} className="logFilter border-transparent outline-none h-full w-[60%] focus:border-none focus:outline-none" type="text" />
                    </div>
                    <button onClick={sendBackendFilter} className="flex bg-[#ff0000b3] items-center justify-center text-white text-[14px] px-[16px] py-[10px] rounded-lg gap-2">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Filtrar Logs
                    </button>
                </div>
                <div className="machineList max-h-[800px] overflow-auto relative overflow-x-auto sm:rounded-lg mt-[34px] mb-[300px]">
                    <table className="w-full text-sm text-left rtl:text-right">
                        <thead className="text-xs rounded-3xl text-[#475467] bg-[#F9FAFB] font-medium">
                            <tr className="rounded-t-xl text-[12px]">
                                <th scope="col" className="px-6 py-3">
                                    Data
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Categoria
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Mensagem
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.map((e, index) => (
                                <tr key={index} className="Logs bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50">
                                    <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                                        <h1>{formatDate(e.date)}</h1>
                                    </th>
                                    <td className="px-6 py-4">
                                        <h1 className="text-[#027A48] px-2 bg-[#ECFDF3] inline-block rounded-2xl">{e.category}</h1>
                                    </td>
                                    <td className="w-[70%] px-6 py-4">
                                        <h1>{e.message}</h1>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="flex items-center mt-4 gap-2">
                        <button onClick={() => setPage(page - 1)} disabled={page === 1} className="px-3 py-2 bg-gray-300 text-gray-700 rounded-lg disabled:bg-gray-200">
                            <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#000000" />
                            </svg>
                        </button>
                        <span>{page}</span>
                        <button onClick={() => setPage(page + 1)} disabled={drained && page === totalPages} className="px-3 py-2 bg-gray-300 text-gray-700 rounded-lg disabled:bg-gray-200">
                            <svg className="transform rotate-180" width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#000000" />
                            </svg>
                        </button>
                    </div>
                </div>
            </Container>
        </div>
    );
}
