export default function Fixed({action}){
    return (
        <div className="flex items-center sm:flex-col sticky bottom-0 align justify-between uppercase px-10 pr-10 text-xs border-t border-[#F3F3F3] bg-white py-3 z-[101] sm:col sm:text-center sm:gap-5">
            <button onClick={action} 
            
            className='bg-[#ff0000b2] p-5 px-16 sm:px-12 rounded-md uppercase text-white font-bold text-xs transition-all hover:scale-[1.1]'
            >
                Tenho interesse
            </button>

            <span className=' sm:text-[10px] md:text-[12px] md:max-w-[350px]'>Faça um orçamento em nossa empresa e tenha o melhor serviço de tecnologia da sua vida</span>
        </div>
    )
}