import React from "react"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "./Pages/Login"
import Home from "./Pages/Home"
import Logs from "./Pages/Logs";
import { UseTnetContext } from "./Contexts/Context";
import Landing from "./Pages/Landing";
import Products from "./Pages/Products";
let EditionDrafts = [];
window.clearDrafts = ()=>{
    EditionDrafts=[];
}
window.editUser= (id2,data)=>{
    let id = EditionDrafts.findIndex((that)=> {
        if (that.type == "userEdited" && that.id == id2) {
            return true;
        }
    });
    if (id >= 0 ) {
        EditionDrafts[id].data ={...EditionDrafts[id].data,...data};
    } else {
        EditionDrafts.push({id:id2,type:"userEdited",data});
    }
} 
let machineidcounter=0;
window.createMachine = (data={}) => {
    machineidcounter++;
    let machineId = machineidcounter;
    EditionDrafts.push({
        type:"machine",
        data,
        id:machineId.toString()
    })
    return machineId.toString();
}
window.editMachine= (id2,data={})=>{
    if (typeof id2 == "string") {
        let id = EditionDrafts.findIndex((that)=> {
            if (that.type == "machine" && that.id == id2) {
                return true;
            }
        });
        
            console.log("setted ",id,id2,data)
        if (id >= 0 ) {
            
            EditionDrafts[id].data ={...EditionDrafts[id].data,...data};
        }
    } else {
        let index = EditionDrafts.findIndex((th)=>th.id==id2&&th.type=="machineEdited")
        if (index >=0) {
            EditionDrafts[index].data = ({ ...EditionDrafts[index].data,...data});

        } else {
            EditionDrafts.push({id:id2,type:"machineEdited",data});

        }
    }
} 
window.removeMachine = (id)=>{
    if (typeof id == "string") {

        EditionDrafts = EditionDrafts.filter((that) => !(that.id == id && that.type=="machine"));
    
    } else {
        EditionDrafts.push({
            type:"RemoveMachine",
            id
        })
    }
};
function hasc(data) {
   for (let field of Object.keys(data)) {
        switch(field) {
            case "port": 
                data["porta"] =parseInt(data.port);
                delete data["port"];
                break;      
        }
   }
    return data;
}
window.applyDrafts = () => {
    let removedIds = [];
    for (let packet of EditionDrafts) {
        switch(packet.type) {
            case "RemoveMachine": {
                if(removedIds.includes(packet.id) || typeof packet.id == "string") {
                    break;
                }
                removedIds.push(packet.id)
                window.sendRequest("/api/machine/"+packet.id,{},"DELETE").then(()=> {}).catch(({errorCode})=>{
                   window.TriggerError(errorCode);
                })
                break;

            }
            case "userEdited": {
                window.sendRequest("/api/usuarios/"+packet.id,packet.data,"PUT").then(()=> {}).catch(({errorCode})=>{
                   window.TriggerError(errorCode);
                })
                break;
            }
            case "machineEdited": {

                window.sendRequest("/api/machine/"+packet.id,hasc(packet.data),"PUT").then(()=> {}).catch(({errorCode})=>{
                   window.TriggerError(errorCode);
                })
                break;
            }
            case "machine": {
                window.sendRequest("/api/machine",hasc(packet.data)).then(()=> {}).catch(({errorCode})=>{
                   window.TriggerError(errorCode);
                })
                break;
            }
        }
    }
    EditionDrafts=[];
}
window.TriggerError = (errorCode) => {
    switch(errorCode) {
      
        case "BAD_EMAIL_OR_PASSWORD": 
            alert("Email ou senha invalida.")
            break;
        case "NO_PERMISSION": 
            alert("Voce não tem permissão para realizar está ação.")
            break;
        case "NO_UNIQUE_EMAIL_OR_BAD_DATA": 
            alert("Email já usado.")
            break;
        case "NOT_FOUND": 
            alert("Elemento não encontrado.")
            break;
        case "BAD_DATA": 
            alert("Dados invalidos.")
            break;
        case "TOO_MANY_REQUESTS": 
            alert("Tente novamente mais tarde.")
            break;
        default: 
            alert(errorCode);
            break;
            
    }
}

window.sendRequest = (url,data,method)=> {
    return new Promise(async (resolve,reject) => {
        try {
            let req
            if (data) {
                let body= JSON.stringify(data);
                req = await fetch(url,{
                    method:method|| "POST",
                    body,
                    headers:{
                        "Content-Type":"application/json",
                        "Content-Lenght":body.length
                    }
                });
            } else {
                req = await fetch(url);
            }
            if (req.status == 204) {
                resolve();
            } else if (req.status == 200) {
                resolve(await req.json())
            }else if (req.status==429) {
                reject("TOO_MANY_REQUESTS")
            } else {
                let data = (await req.text())
                try {
                    reject(JSON.parse(data))
                } catch {
                    reject(data);
                }
            }
        } catch {
            reject("Servidor Offline");
        }
    })
}




export default function App() {
    return (
        <UseTnetContext>
            <Router>
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/dashboard" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/logs" element={<Logs />} />
                </Routes>
            </Router>
        </UseTnetContext>
    )
}