import { UseTnetCtx } from "../../Contexts/Context";
import ChartComponent from "../Chart";

export default function Statistics() {

    const {
        users,
        sellers,
        setSellers,
        formatPrice,
        playersConnected
    } = UseTnetCtx()
    
    return (
        <div className="flex justify-between mt-8 align-items items-start">
            <div className="flex flex-col gap-4">
                <div>
                    <h1 className="text-[14px]">Total de Vendas</h1>
                    <p className="flex items-center text-[36px] font-bold"><span className="text-[20px] font-medium">R$</span> {formatPrice(sellers.amount)}
                        <span className="flex items-center ml-4 px-2 bg-[#ECFDF3] rounded-2xl">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 9.5V2.5M6 2.5L2.5 6M6 2.5L9.5 6" stroke="#12B76A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <h1 className="text-[14px] text-[#027A48]">7.4%</h1>
                        </span>
                    </p>
                </div>
            </div>
            <div className="w-[672px] h-[200px] mx-[32px]">
                <ChartComponent />
            </div>
            <div className="flex flex-col">
                <div className="flex flex-col">
                <h1 className="text-[14px]">Total de Clientes</h1>
                    <p className="flex items-center text-[36px] font-bold"><span className="text-[20px] font-medium"></span> {users.length}
                        <span className="flex items-center ml-4 px-2 bg-[#ECFDF3] rounded-2xl">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 9.5V2.5M6 2.5L2.5 6M6 2.5L9.5 6" stroke="#12B76A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <h1 className="text-[14px] text-[#027A48]">7.4%</h1>
                        </span>
                    </p>
                </div>
                <div>
                    <h1 className="text-[14px]">Jogadores Conectados</h1>
                    <p className="flex items-center text-[36px] font-bold"><span className="text-[20px] font-medium"></span> {playersConnected}
                        <span className="flex items-center ml-4 px-2 bg-[#ECFDF3] rounded-2xl">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 9.5V2.5M6 2.5L2.5 6M6 2.5L9.5 6" stroke="#12B76A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <h1 className="text-[14px] text-[#027A48]">7.4%</h1>
                        </span>
                    </p>
                </div>
                <div>
                    <h1 className="text-[14px]">Status Operacional</h1>
                    <p className="flex items-center text-[36px] font-bold"><span className="text-[20px] font-medium"></span> 82%
                        <span className="flex items-center ml-4 px-2 bg-[#ECFDF3] rounded-2xl">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 9.5V2.5M6 2.5L2.5 6M6 2.5L9.5 6" stroke="#12B76A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <h1 className="text-[14px] text-[#027A48]">7.4%</h1>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}