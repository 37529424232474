
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';

const listStyle = "hover:bg-white hover:py-[4px] px-[12px] py-[8px] hover:rounded-3xl hover:cursor-pointer hover:text-black"
const activeStyle = "bg-white text-black px-[12px] py-[4px] rounded-3xl cursor-pointer"


export default function Header({ navList }) {

    const location = useLocation();
    const navigate = useNavigate();

    const isActive = (route) => location.pathname === route;

    const [hasConnection, setConnection] = useState(false)

    const handleConnection = () => {
        if (hasConnection) {
            window.sendRequest("/api/logout")
            .catch(({errorCode})=>{
                window.TriggerError(errorCode);
            })
            navigate('/')
        } else {
            navigate('/login')
        }
    }

    const safeSmooth = (e) => {
        e.preventDefault()
        document.getElementById("leads").scrollIntoView({
            behavior: "smooth"
        });
    }
    return (
        <div className="
            px-[312px] 
            md:px-[50px]
            lg:px-[80px]
            sm:px-[20px]
            h-[60px] flex items-center space-x-4 bg-[#ff0000b2]">

            <div className="flex-1 flex justify-start">
                <img onClick={() => navigate('/')} className="
                w-[64px]
                h-[36px] 
          

                hover:cursor-pointer" src="/logo2.svg" alt="" />
            </div>

            <nav className="flex-1 flex justify-center">
                <ul className="flex items-center bg-[#f7b6ad1f] rounded-[28px] custom-border text-white text-[14px] md:text-[18px] lg:text-[20px] px-[25px]">
                    {
                        navList?.map((e,key) => (
                            <li
                                key = {key}
                                className={`${isActive(e.route) ? activeStyle : listStyle}`}
                                onClick={() => navigate(e.route)}
                            >{e.label}</li>
                        ))
                    }
                </ul>
            </nav>

            <div className="flex-1 flex justify-end gap-4 items-center">
                <h1 onClick={handleConnection} className="btn text-[#FFF] text-[14px] md:text-[18px] lg:text-[20px] sm:hidden">
                    {hasConnection ? 'Logout' : 'Login'}
                </h1>
                <button onClick={safeSmooth} className="btn h_btn 
                px-[15px] 
                py-[10px] 

                sm:px-[5px]
                sm:py-[8px]

                text-[14px]
                md:text-[18px] lg:text-[20px]

                text-[#FFF]
                sm:hidden
                ">Encomendar Base</button>
            </div>
        </div>


    )
}